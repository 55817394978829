<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useBaseResourceStore from '@/stores/module/base/useBaseResourceStore';
import BaseResourcePickerOption from './BaseResourcePickerOption.vue';

const LabelComponent = ({ option, props }) => h(BaseResourcePickerOption, {
  props: {
    resource: option,
    ...props?.labelProps ?? {},
  },
});

export default Vue.component('BaseResourcePicker', {
  emits,
  setup(_, ctx) {
    const { pickerRemoteResource } = useBaseResourceStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz zasób z listy',
      reduce: (option) => option.id,
      filter: ['name', 'category.name'],
      options: pickerRemoteResource.state,
      ...ctx.attrs,
    }, {
      option: LabelComponent,
      'selected-option': LabelComponent,
    }));
  },
  components: { VsPickerVue },
});
</script>
