var render = function render(){
  var _vm$resource$type;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-resource-picker-option"
  }, [_vm.withType ? _c('div', {
    staticClass: "base-resource-picker-option__with-type"
  }, [_c('div', {
    staticClass: "base-resource-picker-option__type"
  }, [_vm._v(" " + _vm._s(_vm.$t(`base.global.resourceType.${(_vm$resource$type = _vm.resource.type) === null || _vm$resource$type === void 0 ? void 0 : _vm$resource$type.slug}`)) + " "), _vm.resource.category ? [_vm._v(" (" + _vm._s(_vm.resource.category.name) + ") ")] : _vm._e()], 2), _c('div', {
    staticClass: "base-resource-picker-option__name"
  }, [_vm._v(" " + _vm._s(_vm.resource.name) + " ")])]) : _c('div', {
    staticClass: "base-resource-picker-option__name"
  }, [_vm._v(" " + _vm._s(_vm.resource.name) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }