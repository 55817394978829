import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useBaseApi from '@/hooks/base/useBaseApi';

const { fetchWorkTypes } = useBaseApi();

export default defineStore('baseWorkType', () => {
  const { state, fetch, fetched } = useRemoteResource(
    'base.workType',
    fetchWorkTypes,
  );

  return {
    state,
    fetch,
    fetched,
  };
});
