var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.resourceName && _vm.resourceRoute ? _c('abstract-module-label', {
    attrs: {
      "icon": "TruckIcon",
      "to": _vm.resourceRoute,
      "name": _vm.resourceName,
      "target": _vm.target
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }