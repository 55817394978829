<template>
  <div class="base-resource-report-form">
    <vs-form
      v-model="report"
      :errors="errors"
      :i18nNamespace="`form.base.report.${selectedResource.type?.slug}`"
      fallbackI18nNamespace="form.base.report.default"
    >
      <vs-form-group
        field="base_resource_id"
      >
        <base-resource-picker
          v-model="report.base_resource_id"
          :excluded="shouldResourceBeExcluded"
          v-show="resourceEditable"
          @pick="changeSelectedResource"
        />
        <base-resource-label
          :resource="selectedResource"
          :type="selectedResource?.type?.slug"
          v-show="!resourceEditable && selectedResource"
          target="_blank"
        />
      </vs-form-group>

      <vs-form-group
        field="report_date"
        >
        <vs-datepicker withTime v-model="report.report_date" />
      </vs-form-group>

      <vs-form-group
        field="reporter_id"
        >
        <core-user-picker
          v-model="report.reporter_id"
          :clearable="false"
        />
      </vs-form-group>

      <vs-form-group
        field="operator_id"
        >
        <cadre-employee-picker
          v-model="report.operator_id"
          :whitelist="employeeWhitelist"
        />
      </vs-form-group>

      <vs-form-group
        v-if="!refuel"
        v-show="selectedResource?.id"
        field="base_work_type_id"
      >
        <base-work-type-picker
          v-model="report.base_work_type_id"
          :optionFilter="(w) => w.resource_types
            .find((t) => t.slug === selectedResource?.type?.slug)"
        />
      </vs-form-group>

      <vs-form-group
        field="amount_fuel"
        v-else
        >
        <b-input-group>
          <template #append>
            <b-input-group-text>l</b-input-group-text>
          </template>

          <b-form-input
            :placeholder="$t('base.machines.mth.field.fuel')"
            v-model="report.amount_fuel"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="meter_status"
        :forcedLabel="currentUnit?.name"
      >
        <b-input-group>
          <template #append>
            <b-input-group-text>
              {{ currentUnit?.code ?? 'mth' }}
            </b-input-group-text>
          </template>

          <b-form-input
            :placeholder="currentMeterStatus ?? '0'"
            v-model="report.meter_status"
          />
        </b-input-group>

        <div
          class="current-meter-status-info"
          v-if="currentMeterStatusInfo"
        >
          <feather-icon icon="InfoIcon" size="16" class="icon" />
          {{ currentMeterStatusInfo }}
        </div>

      </vs-form-group>

      <vs-form-group
        field="purpose"
        v-if="selectedResource.type?.slug === 'car'"
      >
        <b-form-textarea v-model="report.purpose" />
      </vs-form-group>

      <vs-form-group
        field="route_description"
        v-if="selectedResource.type?.slug === 'car'"
      >
        <b-form-textarea v-model="report.route_description" />
      </vs-form-group>

      <vs-form-group
        field="comment"
        >
        <b-form-textarea v-model="report.comment" />
      </vs-form-group>

    </vs-form>
  </div>
</template>

<script>
import {
  BInputGroup,
  BInputGroupText,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';
import { computed, ref, watch } from 'vue';
import BaseWorkTypePicker from '@/components/module/base/BaseWorkTypePicker.vue';
import CadreEmployeePicker from '@/components/module/cadre/CadreEmployeePicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import BaseResourcePicker from '@/components/module/base/BaseResourcePicker.vue';
import store from '@/store';
import BaseResourceLabel from '../BaseResourceLabel.vue';

export default {
  name: 'MachineMthReportForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
    forEquipment: {
      type: Boolean,
      default: false,
    },
    refuel: {
      type: Boolean,
      default: false,
    },
    machineWhitelist: {
      type: Array,
    },
    employeeWhitelist: {
      type: Array,
    },
    resourceEditable: {
      type: Boolean,
      default: true,
    },
    assignableResourceType: {
      type: String,
    },
  },
  setup(props) {
    store.dispatch('units/fetch');
    const report = computed(() => props.value);

    const selectedResource = ref({});
    const changeSelectedResource = (resource) => {
      selectedResource.value = resource;
    };

    const errors = ref({});
    watch(() => props.feedback, () => { errors.value = props.feedback; });

    const shouldResourceBeExcluded = (resource) => {
      if (!props.assignableResourceType) return false;

      const hasAppropriateType = resource.type?.slug === props.assignableResourceType;
      if (!hasAppropriateType) return true;

      const isReportable = resource.category?.be_reported;
      if (!isReportable) return true;

      return false;
    };

    const currentUnit = computed(() => {
      if (!selectedResource.value.category?.be_reported) return null;
      return store.getters['units/byId'](selectedResource.value.category.unit_id) ?? null;
    });

    const currentMeterStatus = computed(() => selectedResource.value
      ?.meter_status?.status ?? null);

    const currentMeterStatusInfo = computed(() => {
      if (!currentMeterStatus.value) return null;
      return `Aktualny stan licznika: ${currentMeterStatus.value} ${currentUnit.value?.code ?? 'mth'}`;
    });

    return {
      report,
      errors,
      shouldResourceBeExcluded,
      selectedResource,
      changeSelectedResource,
      currentMeterStatusInfo,
      currentMeterStatus,
      currentUnit,
    };
  },
  components: {
    BInputGroup,
    BFormInput,
    BFormTextarea,
    BInputGroupText,
    CadreEmployeePicker,
    BaseWorkTypePicker,
    VsForm,
    VsFormGroup,
    CoreUserPicker,
    VsDatepicker,
    BaseResourcePicker,
    BaseResourceLabel,
  },
};
</script>

<style lang="scss" scoped>
.current-meter-status-info {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--vs-blue);
}
</style>
