<template>
  <div class="base-resource-picker-option">

    <div class="base-resource-picker-option__with-type" v-if="withType">
      <div class="base-resource-picker-option__type">
        {{ $t(`base.global.resourceType.${resource.type?.slug}`) }}
        <template v-if="resource.category">
          ({{ resource.category.name }})
        </template>
      </div>
      <div class="base-resource-picker-option__name">
        {{ resource.name }}
      </div>
    </div>

    <div class="base-resource-picker-option__name" v-else>
      {{ resource.name }}
    </div>

  </div>
</template>

<script>
export default {
  name: 'BaseResourcePickerOption',
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
    withType: {
      type: Boolean,
      default: false,
    },
  },
  components: {
  },
};
</script>

<style lang="scss" scoped>
.base-resource-picker-option {
  display: flex;
  align-items: center;
  gap: 10px;

  &__type {
    opacity: 0.5;
  }
}
</style>
