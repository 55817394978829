var render = function render(){
  var _vm$selectedResource$, _vm$selectedResource, _vm$selectedResource$2, _vm$selectedResource2, _vm$currentUnit, _vm$currentMeterStatu, _vm$selectedResource$3, _vm$selectedResource$4;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-resource-report-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": `form.base.report.${(_vm$selectedResource$ = _vm.selectedResource.type) === null || _vm$selectedResource$ === void 0 ? void 0 : _vm$selectedResource$.slug}`,
      "fallbackI18nNamespace": "form.base.report.default"
    },
    model: {
      value: _vm.report,
      callback: function ($$v) {
        _vm.report = $$v;
      },
      expression: "report"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "base_resource_id"
    }
  }, [_c('base-resource-picker', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.resourceEditable,
      expression: "resourceEditable"
    }],
    attrs: {
      "excluded": _vm.shouldResourceBeExcluded
    },
    on: {
      "pick": _vm.changeSelectedResource
    },
    model: {
      value: _vm.report.base_resource_id,
      callback: function ($$v) {
        _vm.$set(_vm.report, "base_resource_id", $$v);
      },
      expression: "report.base_resource_id"
    }
  }), _c('base-resource-label', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.resourceEditable && _vm.selectedResource,
      expression: "!resourceEditable && selectedResource"
    }],
    attrs: {
      "resource": _vm.selectedResource,
      "type": (_vm$selectedResource = _vm.selectedResource) === null || _vm$selectedResource === void 0 ? void 0 : (_vm$selectedResource$2 = _vm$selectedResource.type) === null || _vm$selectedResource$2 === void 0 ? void 0 : _vm$selectedResource$2.slug,
      "target": "_blank"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "report_date"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "withTime": ""
    },
    model: {
      value: _vm.report.report_date,
      callback: function ($$v) {
        _vm.$set(_vm.report, "report_date", $$v);
      },
      expression: "report.report_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "reporter_id"
    }
  }, [_c('core-user-picker', {
    attrs: {
      "clearable": false
    },
    model: {
      value: _vm.report.reporter_id,
      callback: function ($$v) {
        _vm.$set(_vm.report, "reporter_id", $$v);
      },
      expression: "report.reporter_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "operator_id"
    }
  }, [_c('cadre-employee-picker', {
    attrs: {
      "whitelist": _vm.employeeWhitelist
    },
    model: {
      value: _vm.report.operator_id,
      callback: function ($$v) {
        _vm.$set(_vm.report, "operator_id", $$v);
      },
      expression: "report.operator_id"
    }
  })], 1), !_vm.refuel ? _c('vs-form-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: (_vm$selectedResource2 = _vm.selectedResource) === null || _vm$selectedResource2 === void 0 ? void 0 : _vm$selectedResource2.id,
      expression: "selectedResource?.id"
    }],
    attrs: {
      "field": "base_work_type_id"
    }
  }, [_c('base-work-type-picker', {
    attrs: {
      "optionFilter": function (w) {
        return w.resource_types.find(function (t) {
          var _vm$selectedResource3, _vm$selectedResource4;
          return t.slug === ((_vm$selectedResource3 = _vm.selectedResource) === null || _vm$selectedResource3 === void 0 ? void 0 : (_vm$selectedResource4 = _vm$selectedResource3.type) === null || _vm$selectedResource4 === void 0 ? void 0 : _vm$selectedResource4.slug);
        });
      }
    },
    model: {
      value: _vm.report.base_work_type_id,
      callback: function ($$v) {
        _vm.$set(_vm.report, "base_work_type_id", $$v);
      },
      expression: "report.base_work_type_id"
    }
  })], 1) : _c('vs-form-group', {
    attrs: {
      "field": "amount_fuel"
    }
  }, [_c('b-input-group', {
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('b-input-group-text', [_vm._v("l")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('base.machines.mth.field.fuel')
    },
    model: {
      value: _vm.report.amount_fuel,
      callback: function ($$v) {
        _vm.$set(_vm.report, "amount_fuel", $$v);
      },
      expression: "report.amount_fuel"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "meter_status",
      "forcedLabel": (_vm$currentUnit = _vm.currentUnit) === null || _vm$currentUnit === void 0 ? void 0 : _vm$currentUnit.name
    }
  }, [_c('b-input-group', {
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        var _vm$currentUnit$code, _vm$currentUnit2;
        return [_c('b-input-group-text', [_vm._v(" " + _vm._s((_vm$currentUnit$code = (_vm$currentUnit2 = _vm.currentUnit) === null || _vm$currentUnit2 === void 0 ? void 0 : _vm$currentUnit2.code) !== null && _vm$currentUnit$code !== void 0 ? _vm$currentUnit$code : 'mth') + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": (_vm$currentMeterStatu = _vm.currentMeterStatus) !== null && _vm$currentMeterStatu !== void 0 ? _vm$currentMeterStatu : '0'
    },
    model: {
      value: _vm.report.meter_status,
      callback: function ($$v) {
        _vm.$set(_vm.report, "meter_status", $$v);
      },
      expression: "report.meter_status"
    }
  })], 1), _vm.currentMeterStatusInfo ? _c('div', {
    staticClass: "current-meter-status-info"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "InfoIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.currentMeterStatusInfo) + " ")], 1) : _vm._e()], 1), ((_vm$selectedResource$3 = _vm.selectedResource.type) === null || _vm$selectedResource$3 === void 0 ? void 0 : _vm$selectedResource$3.slug) === 'car' ? _c('vs-form-group', {
    attrs: {
      "field": "purpose"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.report.purpose,
      callback: function ($$v) {
        _vm.$set(_vm.report, "purpose", $$v);
      },
      expression: "report.purpose"
    }
  })], 1) : _vm._e(), ((_vm$selectedResource$4 = _vm.selectedResource.type) === null || _vm$selectedResource$4 === void 0 ? void 0 : _vm$selectedResource$4.slug) === 'car' ? _c('vs-form-group', {
    attrs: {
      "field": "route_description"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.report.route_description,
      callback: function ($$v) {
        _vm.$set(_vm.report, "route_description", $$v);
      },
      expression: "report.route_description"
    }
  })], 1) : _vm._e(), _c('vs-form-group', {
    attrs: {
      "field": "comment"
    }
  }, [_c('b-form-textarea', {
    model: {
      value: _vm.report.comment,
      callback: function ($$v) {
        _vm.$set(_vm.report, "comment", $$v);
      },
      expression: "report.comment"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }