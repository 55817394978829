import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useBaseApi from '@/hooks/base/useBaseApi';

const { fetchPickerResources } = useBaseApi();

export default defineStore('baseResource', () => {
  const pickerRemoteResource = useRemoteResource('base.resource', fetchPickerResources);

  return {
    pickerRemoteResource,
  };
});
