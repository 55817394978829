<template>
  <abstract-module-label
    v-if="resourceName && resourceRoute"
    icon="TruckIcon"
    :to="resourceRoute"
    :name="resourceName"
    :target="target"
  />
</template>

<script>
import { computed } from 'vue';
import AbstractModuleLabel from '@/components/abstract/AbstractModuleLabel.vue';

export default {
  name: 'BaseResourceLabel',
  props: {
    resource: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: Number,
    },
    target: {
      type: String,
    },
    type: {
      type: String,
      validator: (v) => ['vehicle', 'tool', 'car'].includes(v),
    },
  },
  setup(props) {
    const resourceType = computed(() => {
      if (props.type) return props.type;
      return props.resource?.type?.slug ?? 'vehicle';
    });

    const routeType = computed(() => {
      if (resourceType.value === 'vehicle') return 'machines';
      if (resourceType.value === 'tool') return 'equipment';
      return 'cars';
    });

    const resourceId = computed(() => props.id ?? props.resource.id ?? null);
    const resourceName = computed(() => props.resource?.name);

    const resourceRoute = computed(() => ({
      name: `base.${routeType.value}.details`,
      params: {
        id: resourceId.value,
      },
    }));

    return { resourceRoute, resourceName };
  },
  components: {
    AbstractModuleLabel,
  },
};
</script>
