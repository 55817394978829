<template>
  <vs-modal
    :modalInstance="modalCreateReport"
    @ok="createReport"
  >
    <resource-report-form
      v-model="fields"
      :feedback="feedback"
      :refuel="reportFuel"
      :resourceEditable="resourceEditable"
      :assignableResourceType="type"
    />
  </vs-modal>
</template>

<script>
import { ref } from 'vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import formatDate from '@/libs/date-formatter';
import store from '@/store';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { CreateMachineReportSuccess } from '@/libs/toasts/feedback/module/base';
import ResourceReportForm from '../../resource/forms/ResourceReportForm.vue';

export default {
  name: 'BaseResourceReportCreateModal',
  emits: ['ok'],
  props: {
    type: {
      type: String,
    },
    resourceEditable: {
      type: Boolean,
      default: true,
    },
  },
  setup(_, { emit }) {
    const { modalCreateReport } = useBaseModals();

    const {
      fields,
      feedback,
      setFieldDefaults,
      assignFields,
      resetFields,
      areAllFieldsValid,
      nonEmptyFields,
      errors,
    } = useValidatedForm({
      base_resource_id: 'required|integer',
      amount_fuel: 'required_or:@base_work_type_id|integer',
      base_work_type_id: 'required_or:@amount_fuel',
      comment: '',
      purpose: '',
      route_description: '',
      meter_status: 'required|integer',
      operator_id: 'required|integer',
      report_date: 'required',
      reporter_id: 'required|integer',
    });

    setFieldDefaults({
      reporter_id: store.getters['auth/user']?.id,
      report_date: formatDate.standardTimed(Date.now()),
    });

    const reportFuel = ref(false);
    modalCreateReport.value.on({
      open(data) {
        resetFields();
        setFieldDefaults({ report_date: formatDate.standardTimed(Date.now()) });
        reportFuel.value = data.reportFuel ?? false;
        assignFields(data);
      },
      close: () => {
        modalCreateReport.value.cleanData();
        resetFields();
      },
    });

    const createReport = async () => {
      if (!(await areAllFieldsValid())) return;

      modalCreateReport.value.isLoading = true;

      useBaseApi()
        .createMachineReport({ ...nonEmptyFields.value })
        .then(({ data }) => {
          showToast(CreateMachineReportSuccess, { name: '' });
          emit('ok', { ...data.data, reportedFuel: reportFuel.value });
          modalCreateReport.value.close();
        })
        .catch(({ response }) => {
          errors.serverSide.value = response.data?.errors;
        })
        .finally(() => {
          modalCreateReport.value.isLoading = false;
        });
    };

    return {
      modalCreateReport,
      fields,
      feedback,
      createReport,
      errors,
      reportFuel,
    };
  },
  components: {
    ResourceReportForm, VsModal,
  },
};

</script>
